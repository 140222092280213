import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setState, getState } from '../../utils/localStorage';

export interface ISessionState {
    username: string;
    chatMode: 'agent' | 'bot';
    chatId: string;
    tabId: string;
    sessionId: string;
    botId: string;
    acctId: string;
    brandId: string;
    socketPaths: [string] | [];
    isStartBot: boolean;
    isStartAgent: boolean;
    isBotToAgent: boolean;
    respondedToBot: boolean;
    respondedToAgent: boolean;
    chatCountReason: string;
    apiPath: string;
    laAffinityToken: string;
    laSessionId: string;
    laSessionKey: string;
    laId: string;
    laName: string;
    dbProfile: 'mario.production' | 'mario.staging.1';
    formContent: object;
    isVip: [any, any];
    isCached: boolean;
    intentId: string | null;
    betslipId: string;
    matchStartDate: string;
    nps: string;
    percentage: string;
    queue: string;
    chatRatingDone: boolean;
    sentBetSlip: boolean;
    betSlipVals: [string] | []; 
    debugMessage: [string] | [];
    intentSuggestion: any;
    intentChoice: string;
    postChatUrl: string;
    refreshWhenBot: boolean;
    referrerSite: string;
    refreshCounter: string;
    currentStatusPlugin: string;
    currentStatusClient: string;
    queuePosition: string;
    usernameFromUrl: boolean;
    queueStatus: string;
    refreshWhenQueueing: boolean;
    caseNumber: string;
    caseCategory: string;
    sentCaseDetails: boolean;
    enQueue: boolean;

}

const initialState: ISessionState = {
    username: '',
    chatMode: 'bot',
    chatId: '',
    tabId: '',
    sessionId: '',
    botId: '',
    acctId: '',
    brandId: '',
    apiPath: '',
    dbProfile: 'mario.staging.1',
    isVip: [false, "notChecked"],
    isCached: false,
    socketPaths: [],
    isStartBot: false,
    isStartAgent: false,
    isBotToAgent: false,
    respondedToBot: false,
    respondedToAgent: false,
    chatCountReason: '',
    laAffinityToken: '',
    laSessionId: '',
    laSessionKey: '',
    laId: '',
    laName: '',
    formContent: {},
    intentId: '',
    betslipId: '',
    matchStartDate: '',
    nps: '',
    percentage: '',
    queue: '',
    chatRatingDone: false,
    sentBetSlip:false,
    betSlipVals: [],
    debugMessage: [],
    intentSuggestion: [],
    intentChoice: '',
    postChatUrl: '',
    refreshWhenBot: false,
    refreshCounter: "0",
    currentStatusPlugin: "",
    currentStatusClient: "",
    queuePosition: '',
    usernameFromUrl: false,
    referrerSite: "",
    queueStatus: '',
    refreshWhenQueueing: false,
    caseNumber: '',
    caseCategory: '',
    sentCaseDetails: false,
    enQueue: false,
};

export const sessionSlice = createSlice({
    name: 'sessionState',
    initialState,
    reducers: {
        editSession: (state: any, action) => {
            console.log(
                'editSession',
                action.payload.name,
                action.payload.value
            );
            state[action.payload.name] = action.payload.value;
        },
        editSessions: (state: any, action) => {
            console.log('editSessions', action.payload);
            return { ...state, ...action.payload };
        },
        appendSession: (state: any, action) => {
            console.log(
                'appendSession',
                action.payload.name,
                action.payload.value
            );
            state[action.payload.name].push(action.payload.value);
        },
    },
});

// Action creators are generated for each case reducer function
export const { editSession, editSessions, appendSession } =
    sessionSlice.actions;

export default sessionSlice.reducer;
