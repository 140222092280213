import { useEffect, useState, createContext, Children } from 'react';
import { info, error, warning } from '../utils/console';
import { useSelector, useDispatch } from 'react-redux';
import { editStatus, incStatus } from '../redux/state/status';
import { generateChatMode, generateToken } from '../utils/tokenGenerator';
import {
    ChatInterface,
    ReceiveAuthenticatedEvent,
    ReceiveEventInterface,
    SendChasitorMessageEventInterface,
    SendChatEndInterface,
    SendChatRatingInterface,
    SendCheckVipEventInterface,
    SendConnectAgentEventInterface,
    SendConsumeMessageEventInterface,
    SendEventInterface,
    SendFormSubmitEventInterface,
    SendHandshakeTokenEvent,
    SendIntentEventInterface,
    SendMessageEventInterface,
    SendReconnectAgentEventInterface,
    SendUploadChatEventInterface,
    SendUploadFileEventInterface,
    SendVerifyReconnectionEventInterface,
} from '../utils/interfaces';
import { useCacheService } from '../hook/useCacheService';
import { useSessionService } from '../hook/useSessionService';
import { addDialog, removeDialog } from '../redux/state/dialog';
import { useTranslation } from 'react-i18next';
import {
    appendSession,
    editSession,
    editSessions
} from '../redux/state/session';
import { format } from 'date-fns';
import { addChat } from '../redux/state/chat';
import { func, param } from 'ts-interface-checker';
import Swal from 'sweetalert2';
import { useSearchParams } from 'react-router-dom';
import { getBrowser, getPlatform } from '../utils/salesforceVisitorDetails';

export const SocketContext = createContext<any>({} as any);

interface Props {
    // session: {
    //     acctId: number;
    //     botId: number;
    //     chatId: string;
    //     chatMode: string;
    //     isCached: boolean;
    //     sessionId: string;
    //     username: string;
    //     isVip: boolean;
    //     tabId: number;
    //     brandId: 'welton' | 'masuta' | 'vpo';
    //     dbProfile: 'mario.staging.1' | 'mario.production';
    // };
    session: any;
    systemMessages: any;
    settings: any;
    socketPaths: string[];
    children: JSX.Element;
    setDebug: any;
    debug: any;
}

declare const window: any;

const SocketContextProvider = (props: Props) => {
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [path, setPath] = useState<string | URL>('null');
    const [chatId, setChatId] = useState('');
    const [chatModeLocal, setChatModeLocal] = useState('bot');
    const [agentName, setAgentName] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const session = useSelector((state: { session: any }) => state.session);
    const sessionService = useSessionService();
    const botIdRequireLanguageSelection = [49, 75, 88, '49', '75', '88'];
    const chatMode = useSelector(
        (state: { session: { chatMode: string } }) => state.session.chatMode
    );
    const isStartBot = useSelector(
        (state: { session: { isStartBot: boolean } }) =>
            state.session.isStartBot
    );
    const isStartAgent = useSelector(
        (state: { session: { isStartAgent: boolean } }) =>
            state.session.isStartAgent
    );
    const isBotToAgent = useSelector(
        (state: { session: { isBotToAgent: boolean } }) =>
            state.session.isBotToAgent
    );
    const respondedToBot = useSelector(
        (state: { session: { respondedToBot: boolean } }) =>
            state.session.respondedToBot
    );
    const respondedToAgent = useSelector(
        (state: { session: { respondedToAgent: boolean } }) =>
            state.session.respondedToAgent
    );
    const chatCountReason = useSelector(
        (state: { session: { chatCountReason: string } }) =>
            state.session.chatCountReason
    );
    const username = useSelector(
        (state: { session: { username: string } }) => state.session.username
    );
    const botButtonColor = useSelector(
        (state: { settings: { botButtonColor: string } }) =>
            state.settings.botButtonColor
    );
    const customValues = useSelector(
        (state: { settings: { customValues: string } }) =>
            state.settings.customValues
    );
    const chats = useSelector((state: { chat: ChatInterface[] }) => state.chat);
    const isChatAlive = useSelector(
        (state: { status: { isChatAlive: boolean } }) =>
            state.status.isChatAlive
    );

    const betslipId = useSelector(
        (state: { session: { betslipId: string } }) => state.session.betslipId
    );

    const matchStartDate = useSelector(
        (state: { session: { matchStartDate: string } }) =>
            state.session.matchStartDate
    );

    const vendor = useSelector(
        (state: { session: { vendor: string } }) => state.session.vendor
    );

    const status = useSelector(
        (state: { session: { status: string } }) => state.session.status
    );

    const referrerSite = useSelector(
        (state: { session: { referrerSite: string } }) => state.session.referrerSite
    );

    const currentStatusPlugin = useSelector(
        (state: { session: { currentStatusPlugin: string } }) =>
            state.session.currentStatusPlugin
    );

    const currentStatusClient = useSelector(
        (state: { session: { currentStatusClient: string } }) =>
            state.session.currentStatusClient
    );

    const queueStatus = useSelector(
        (state: { session: { queueStatus: String } }) => state.session.queueStatus
    );

    const refreshWhenBot = useSelector(
        (state: { session: { refreshWhenBot: boolean } }) =>
            state.session.refreshWhenBot
    );

    const laAffinityToken = useSelector(
        (state: { session: { laAffinityToken: string } }) =>
            state.session.laAffinityToken
    );

    const laSessionKey = useSelector(
        (state: { session: { laSessionKey: string } }) =>
            state.session.laSessionKey
    );

    const refreshWhenQueueing = useSelector(
        (state: { session: { refreshWhenQueueing: string } }) =>
            state.session.refreshWhenQueueing
    );

    const queueNumber = useSelector(
        (state: { status: { queueNumber: number } }) => state.status.queueNumber
    );
    const caseNumber = useSelector(
        (state: { session: { caseNumber: String } }) => state.session.caseNumber
    );

    const caseCategory = useSelector(
        (state: { session: { caseCategory: String } }) => state.session.caseCategory
    );



    const enQueue = useSelector(
        (state: { session: { enQueue: boolean } }) => state.session.enQueue
    );


    const dispatch = useDispatch();
    const {
        setCache,
        checkCache,
        pushCache,
        setCachesByFields,
        setCacheByField,
        getCacheByField,
        getCache,
        popCache,
        endSession,
    } = useCacheService();
    const { t } = useTranslation();

    const [timeoutTimer, setTimeoutTimer] = useState<any>({});
    const [liveAgentSession, setLiveAgentSession] = useState({
        affinityToken: '',
        key: '',
    });

    let offlineCount = 0;
    let onlineCount = 0;
    let chatRequestSuccessCount = 0;
    let vipVals = props.session.isVip;
    console.log("vipVals here",vipVals);

    const socketEvent: any = {
        eventType: 'initial',
        botId: props.session.botId,
        acctId: props.session.acctId,
        brandId: props.session.brandId,
        dbProfile: props.session.dbProfile,
    };

    const startSessionChecker = (chatId: string) => {
        // dispatch(appendSession({ name: 'debugMessage', value: 'start-' }));
        clearInterval(timeoutTimer)
        const t = setInterval(async () => {
            console.log("session check", chatId)
            if (chatId) {
                sendConsumeMessageEvent({
                    chatId,
                });
            }
            sendPingEvent(chatId);
            const ended = await checkCache(
                props.session.sessionId,
                props.session.tabId
            );
            if (ended) {
                // dispatch(
                //     appendSession({ name: 'debugMessage', value: 'end-' })
                // );
                clearInterval(t);
                stopSessionChecker();
            }
        }, 30000);
        setTimeoutTimer(t);
    };

    const stopSessionChecker = async () => {
        // check
        dispatch(appendSession({ name: 'debugMessage', value: 'stop-' }));
        clearInterval(timeoutTimer);
        setTimeoutTimer({});
    };

    const onOpen = (msg: any) => {
        info(onOpen.name, 'on open!');
    };

    const onMessage = (msg: any) => {
        const event = JSON.parse(msg.data);
        if (Array.isArray(event)) {
            event.map((_event: any, index: number) => {
                socketEventHandler(_event);
            });
        } else {
            socketEventHandler(event);
        }
    };

    const checkBetSlipValue = () => {
        if(betslipId) {
            if (matchStartDate && (vendor && status)) {
                return true;
            } else{
                return false;
            }
        } else {
            return false;
        }
    }

    const isAssignedToAgent = () => {
        if (session.betSlipID ) {
            return true;
        }
        else if (caseNumber){
            return true;
        }

        return false;
    }

    const socketEventHandler = (event: any) => {
        console.log('RECEIVE EVENT', event);
        try {
            if (event.eventType === 'pong') {
                // dispatch(appendSession({ name: 'debugMessage', value: 'po-' }));
            }
            if (event.eventType == 'reply') {
                handleReplyEvent(event);
            } else if (event.eventType == 'connected') {
                handleConnectedEvent(event);
            } else if (event.eventType == 'authenticate') {
                handleAuthenticatedEvent(event);
            } else if (event.eventType == 'liveAgentMessageInserted') {
                handleMessageInsertedEvent(event);
            } else if (event.eventType == 'memberVipStatus') {
                handleMemberVipStatus(event);
            } else if (event.eventType == 'liveAgentRequestSuccess') {
                handleLiveAgentRequestSuccessEvent(event);
            } else if (event.eventType == 'liveAgentRequestFail') {
                handleLiveAgentRequestFailEvent(event);
            } else if (event.eventType == 'liveAgentQueueUpdate') {
                handleLiveAgentQueueUpdateEvent(event);
            } else if (event.eventType == 'liveAgentChatEstablished') {
                handleLiveAgentChatEstablishedEvent(event);
            } else if (event.eventType == 'liveAgentChatMessage') {
                handleLiveAgentChatMessageEvent(event);
            } else if (event.eventType == 'liveAgentChatTransferred') {
                handleLiveAgentChatTransferredEvent(event);
            } else if (event.eventType == 'liveAgentRequestFile') {
                handleLiveAgentFileRequestEvent(event);
            } else if (event.eventType == 'liveAgentChatEnded') {
                handleLiveAgentChatEnded(event);
            } else if (event.eventType == 'terminateStatus') {
                handleTerminateStatus(event);
            } else if (event.eventType == 'reconnectionVerified') {
                handleReconnectionVerified(event);
            } else if (event.eventType == 'eventCacheNotFound'){
                handleEventCacheNotFound(event);
            }
        } catch (e: any) {
            error(SocketContextProvider.name, e);
        }
    };

    const handleEventCacheNotFound = (event:ReceiveEventInterface) => {

        if (props.session.laAffinityToken && props.session.laSessionKey){

            sendChatEndEvent({
                chatId: props.session.chatId,
                affinityToken: props.session.laAffinityToken,
                key: props.session.laSessionKey,
            });
            
        }
        endSession(props.session.sessionId);
        window.location.reload();
    }

    const verifyVipChecked = () => {

        console.log("IS VIP CHECK", vipVals, typeof vipVals);
        if (vipVals[1] === "notChecked"){
            console.log("IS VIP UNCHECK HERE");
            let data = JSON.parse(customValues);
            sendCheckVipEvent({
                chatId: chatId,
                username: username,
                usernameFromUrl: true, //check
                recheckVip: true,
                merchant: data.hasOwnProperty('merchant') ? data.merchant : "",
                market: data.hasOwnProperty('market') ? data.market : "",
                checkVipDetails: data['check_vip_details'] ? data['check_vip_details'] : null,
                bbuPromoApiUrl: data.hasOwnProperty('bbu_promo_api_url') ? data.bbu_promo_api_url : "",
                bbuPromoDefaultUrl: data.hasOwnProperty('bbu_promo_default_url') ? data.bbu_promo_default_url : ""
            })
 
        } else if (vipVals[1] === "checked") {
            console.log("IS VIP CHECK HERE")
        }
    }
    
    const handleConnectedEvent = async (event: ReceiveEventInterface) => {
        if (window.isRN) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    key: 'CHECK TOKEN',
                    value: props.session.sessionId,
                })
            );
        }
        console.log('connected event', event, props.session);
        if (!props.session.chatId) {
            dispatch(
                editSession({
                    name: 'chatId',
                    value: event.data.chatId,
                })
            );

            dispatch(
                editStatus({ name: 'queueStatus', value: 'loading' })
            );

            setChatId(event.data.chatId);

            let data = JSON.parse(customValues);

            const handShakeToken = searchParams.get('HandshakeToken');
            const customVal = props.settings.customValues ? JSON.parse(props.settings.customValues) : {};
            const handshakeToken = customVal.handshake_token ? JSON.parse(customVal.handshake_token) : {};
            const isActive = handshakeToken.isActive ? handshakeToken.isActive : 'false';
            
            let isChecked = 'false';
            sendAuthEvent({
                handShakeToken: handShakeToken,
                botId: props.session.botId,
                isActive: isActive,
                chatId: event.data.chatId,
                isChecked: isChecked
            });

            if (props.session.username) {
                let data = JSON.parse(customValues);
                sendCheckVipEvent({
                    chatId: event.data.chatId,
                    username: props.session.username,
                    usernameFromUrl: true, //check
                    recheckVip: false,
                    merchant: data.hasOwnProperty('merchant') ? data.merchant : "",
                    market: data.hasOwnProperty('market') ? data.market : "",
                    checkVipDetails: data['check_vip_details'] ? data['check_vip_details'] : null,
                    bbuPromoApiUrl: data.hasOwnProperty('bbu_promo_api_url') ? data.bbu_promo_api_url : "",
                    bbuPromoDefaultUrl: data.hasOwnProperty('bbu_promo_default_url') ? data.bbu_promo_default_url : "",
                    specUsername : props.session.username,
                    redisUrl     : props.session.apiPath
                });
 
            }
            startSessionChecker(event.data.chatId);
        } else {
            setChatId(props.session.chatId);
            if (props.session.chatMode === 'agent') {
                 // check SF values present in cache

                // if(currentStatusPlugin == "finishRequestSuccess" && currentStatusClient == ""){

                //     let templaAffinityToken = await getCacheByField(props.session.sessionId, 'laAffinityToken')
                //     let templaSessionKey= await getCacheByField(props.session.sessionId, 'laSessionKey')
                //     let templaSessionId = await getCacheByField(props.session.sessionId, 'laSessionId')
                //     let temppostChatUrl = await getCacheByField(props.session.sessionId, 'postChatUrl')
                //     let tempqueuePosition = await getCacheByField(props.session.sessionId, 'queuePosition')

                //     dispatch(
                //         editStatus({
                //             name: 'queueNumber',
                //             value: parseInt(tempqueuePosition) - 1,
                //         })
                //     );
                //     dispatch(
                //         editStatus({
                //             name: 'queueStatus',
                //             value: 'queueing',
                //         })
                //     );
                //     dispatch(
                //         editSessions({
                //             laAffinityToken: templaAffinityToken,
                //             laSessionKey: templaSessionKey,
                //             laSessionId: templaSessionId,
                //             postChatUrl: temppostChatUrl
                //         })
                //     );

                // } 
                // else if (currentStatusPlugin == "startRequestSuccess" && currentStatusClient == ""){
                //     //dont do anything

                // }

                // else {

                //     const laAffinityToken = await getCacheByField(props.session.sessionId, 'laAffinityToken')

                //     if (props.session.isCached || laAffinityToken) {
                //         console.log('reconnect!', props.session.isCached, laAffinityToken) 
                //         sendReconnectAgentEvent({
                //             chatId: props.session.chatId,
                //             username: props.session.username,
                //         });
                //     } else {
                //         verifyVipChecked();
                //         connectAgent({isVip: vipVals[0] , flag: "handleConnectEvent"});
                //     }

                // }
                const laAffinityToken = await getCacheByField(props.session.sessionId, 'laAffinityToken') // check SF values present in cache
                if (props.session.isCached && props.session.refreshWhenQueueing == true){
                    console.log("Refreshed or closed tab during queue page");

                    let logQueueNumber = queueNumber + 1;

                    sendUploadChatEvent({
                        chatId: chatId,
                        speaker: 'Client',
                        speakerName: username,
                        datetime: new Date().toJSON(),
                        message: "Refreshed or closed tab during queue page. Member queue position at " + logQueueNumber.toString(),
                        is_system: true,
                    });

                    dispatch(editStatus({ name: 'queueStatus', value: 'canceled' }));
                    
                    dispatch(
                        addDialog({
                            task: 'ended',
                            type: 'action',
                        })
                    );
                    
                    sendChatEndEvent({
                        chatId: props.session.chatId,
                        affinityToken: props.session.laAffinityToken,
                        key: props.session.laSessionKey,
                    });
                    endSession(props.session.sessionId);
                    window.location.reload();
                    
                } else if (props.session.isCached || laAffinityToken) {
                    console.log('reconnect!', props.session.isCached, laAffinityToken) 
                    sendReconnectAgentEvent({
                        chatId: props.session.chatId,
                        username: props.session.username,
                    });
                } else {
                    verifyVipChecked();
                    connectAgent({isVip: vipVals[0] , flag: "handleConnectEvent"});
                }
                
            } else if (props.session.chatMode === 'bot') {
                if (refreshWhenBot && laAffinityToken && laSessionKey) {
                    console.log("refresh when bot triggered");
                    sendChatEndEvent({
                        chatId: chatId,
                        affinityToken: laAffinityToken,
                        key: laSessionKey,
                    });
                }
                verifyVipChecked();
                console.log('chats after connected', chats);
                if (
                    chats &&
                    chats.length < 1 &&
                    props.session.username &&
                    props.session.username != ''
                ) {
                    if (props.systemMessages.welcome) {
                        const welcome_chat_json: ChatInterface = {
                            owner: 'bot',
                            name: props.settings.botName,
                            type: 'text',
                            firstMessage: true,
                            message: {
                                links: props.systemMessages.welcome.links
                                    ? props.systemMessages.welcome.links
                                          .length > 0
                                        ? props.systemMessages.welcome.links
                                        : null
                                    : null,
                                content: props.systemMessages.welcome.message,
                            },
                            displayTime: format(new Date(), 'hh:mmaa'),
                            createdBy: new Date().getTime(),
                        };
                        dispatch(addChat(welcome_chat_json));
                        dispatch(
                            editSessions({
                                isStartBot: true,
                                chatCountReason: 'isStartBot',
                            })
                        );
                        setCacheByField(
                            props.session.sessionId,
                            'isStartBot',
                            true
                        );
                        pushCache(
                            props.session.sessionId + '_chats',
                            JSON.stringify(welcome_chat_json)
                        );
                        sendUploadChatEvent({
                            chatId: chatId,
                            speaker: 'Bot',
                            speakerName: props.settings.botName,
                            datetime: new Date().toJSON(),
                            message: props.systemMessages.welcome.message,
                        });
                    }
                }

                if (betslipId) {
                    setChatModeLocal('agent');
                    verifyVipChecked();
                    connectAgent({isVip: vipVals[0], flag: "betSlipID"});
                    setCacheByField(props.session.sessionId, 'chatMode', 'agent');
                    dispatch(editSession({ name: 'chatMode', value: 'agent' }));
                } 
            }
            startSessionChecker(props.session.chatId);
            // setChatId(props.session.chatId);
        }
        // console.log(
        //     'update',
        //     dispatch(
        //         sendChatCounts({
        //             data: { isStartBot: true },
        //         })
        //     )
        // );
    };

    const handleMemberVipStatus = (event: ReceiveEventInterface) => {

        // dispatch(editSession({ name: 'isVip', value: event.data.isVip }));
        // setCacheByField(props.session.sessionId, 'isVip', event.data.isVip);
        dispatch(editSession({ name: 'isVip', value: [event.data.isVip, "checked"] }));
        vipVals = [event.data.isVip, "checked"];
        setCacheByField(props.session.sessionId, 'isVip', [event.data.isVip, "checked"]);
        setCacheByField(props.session.sessionId, 'status', 'active');
        setCacheByField(props.session.sessionId, 'chatId', chatId);

        let queueToAgent = isAssignedToAgent();
        if (event.data.recheckVip == false){ 

            const mode = session.percentage
                ? generateChatMode(parseInt(session.percentage), session.queue, queueToAgent)
                : event.data.isVip
                ? generateChatMode(
                    props.settings.routingPercentageForVIP,
                    session.queue,
                    queueToAgent
                )
                : generateChatMode(props.settings.routingPercentage, session.queue, queueToAgent);
            console.log(
                'Routing Percentage:',
                mode,
                session.percentage
                    ? session.percentage
                    : event.data.isVip
                    ? props.settings.routingPercentageForVIP
                    : props.settings.routingPercentage
            );
            if (mode === 'bot' || event.data.specMode === 'bot') {
                dispatch(
                    editStatus({ name: 'queueStatus', value: 'established' })
                );
                
                dispatch(editSession({ name: 'chatMode', value: 'bot' }));

                setChatModeLocal('bot');
                if (props.systemMessages.welcome) {
                    let newLinks = JSON.parse(JSON.stringify(props.systemMessages.welcome.links));
                    let bbuPromoPattern = "BBU_PROMO_URL";
                    let newUrl = ""
                    if (event.data.bbuPromoUrl){
                        newUrl = event.data.bbuPromoUrl;
                        if (newLinks.length > 0){
                            for (let i = 0; i < newLinks.length; i++){
                                if (newLinks[i].url == bbuPromoPattern){
                                    newLinks[i].url = newUrl
                                }
                            }
                        }
                    }

                    const welcome_chat_json: ChatInterface = {
                        owner: 'bot',
                        name: props.settings.botName,
                        type: 'text',
                        firstMessage: true,
                        message: {
                            // links: props.systemMessages.welcome.links
                            //     ? props.systemMessages.welcome.links.length > 0
                            //         ? props.systemMessages.welcome.links
                            //         : null
                            //     : null,
                            links: newLinks
                                ? newLinks.length > 0
                                    ? newLinks
                                    : null
                                : null,
                            content: props.systemMessages.welcome.message,
                        },
                        displayTime: format(new Date(), 'hh:mmaa'),
                        createdBy: new Date().getTime(),
                    };
                    dispatch(addChat(welcome_chat_json));
                    dispatch(
                        editSessions({
                            isStartBot: true,
                            chatCountReason: 'isStartBot',
                        })
                    );
                    setCacheByField(props.session.sessionId, 'isStartBot', true);
                    pushCache(
                        props.session.sessionId + '_chats',
                        JSON.stringify(welcome_chat_json)
                    );
                    sendUploadChatEvent({
                        chatId: chatId,
                        speaker: 'Bot',
                        speakerName: props.settings.botName,
                        datetime: new Date().toJSON(),
                        message: props.systemMessages.welcome.message,
                    });
                }
            } else {
                setChatModeLocal('agent');
                connectAgent({isVip: event.data.isVip, flag: "handleVipStatus"});
                checkIfStartBot();
                if (!isStartBot) {
                    dispatch(
                        editSessions({
                            isStartAgent: true,
                            chatCountReason: 'isStartAgent',
                        })
                    );
                    setCacheByField(props.session.sessionId, 'isStartAgent', true);
                }
                setCacheByField(props.session.sessionId, 'chatMode', 'agent');
                dispatch(editSession({ name: 'chatMode', value: 'agent' }));
            }

            setCacheByField(props.session.sessionId, 'chatRatingDone', false);


            sendUploadChatEvent({
                chatId: chatId,
                speaker: 'Client',
                speakerName: props.session.username,
                datetime: new Date().toJSON(),
                message: "(System message for sending username)",
                is_system: true,
            })
        }
        
        // startSessionChecker();
    }

    const handleAuthenticatedEvent = (event: ReceiveAuthenticatedEvent) => {
        dispatch(
            editSession({
                name: 'isChecked',
                value: event.data.isChecked,
            })
        );
    }
    

    const handleTerminateStatus = (event: ReceiveEventInterface) => {
        const terminateStatus = event.data.isTerminated;
        if (terminateStatus) {
            // connectAgent();
            console.log("terminate status", terminateStatus)
            endSession(props.session.sessionId);
            window.location.reload();
        } else {
            sendVerifyReconnectionEvent({
                chatId,
                affinityToken: props.session.laAffinityToken,
                key: props.session.laSessionKey,
            });
            // dispatch(
            //     editSessions({
            //         laAffinityToken: event.data.live_agent_info.affinityToken,
            //         laSessionKey: event.data.live_agent_info.key,
            //         laSessionId: event.data.live_agent_info.id,
            //     })
            // );
        }
    };

    const handleReconnectionVerified = async (event: ReceiveEventInterface) => {
        //const validSession = await checkValidSession();
        if (event.data.isVerified === 'OK') {
            console.log("handleReconnectionVerified", chatId)
            if (queueStatus == 'queueing'){
                dispatch(
                    editStatus({
                        name: 'queueStatus',
                        value: queueStatus,
                    })
                );
            }
            
            if (chatId) {
                sendConsumeMessageEvent({
                    chatId,
                });

                sendPingEvent(chatId);
            }
        } else {

            verifyVipChecked();
            connectAgent({ isVip:vipVals[0], flag: 'handleReconnectVerified'});

        }

        if (props.session.sentCaseDetails == 'false' && caseNumber && caseCategory){
            dispatch(
                addDialog({
                    task: 'caseDetails',
                    type: 'action',
                    data: '',
                })
            );
        }

        if(checkBetSlipValue() || props.session.betSlipVals){

            let templist = props.session.betSlipVals.split(',');

            dispatch(
                editSession({
                    name: 'betSlipVals',
                    value: templist,
                })
            );

            if (checkBetSlipValue() && props.session.sentBetSlip.toString() !== betslipId){
    
                let slipVals = [vendor, status, betslipId, matchStartDate];
                dispatch(
                    editSession({
                        name: 'betSlipVals',
                        value: slipVals,
                    })
                );

                dispatch(
                    addDialog({
                        task: 'betSlip',
                        type: 'action',
                        data: '',
                    })
                );
    
                setCacheByField(props.session.sessionId, 'betSlipVals', slipVals);

            } else if (props.session.sentBetSlip.toString() !== templist[2]) {
                dispatch(
                    addDialog({
                        task: 'betSlip',
                        type: 'action',
                        data: '',
                    })
                );
            }

        }
    };

    const checkIfStartBot = () => {
        const getValue = getCacheByField(props.session.sessionId, 'isStartBot')
        getValue.then((value:any) => {
            const isStartBot = value
            if (isStartBot === 'true') {
                dispatch(
                    editSession({
                        name: 'isStartBot',
                        value: true,
                    })
                );
                dispatch(
                    editSession({
                        name: 'respondedToBot',
                        value: true,
                    })
                );
                dispatch(
                    editSession({
                        name: 'isStartAgent',
                        value: false,
                    })
                );
                dispatch(
                    editSessions({
                        isBotToAgent: true,
                        chatCountReason: 'isBotToAgent',
                    })
                );
                setCacheByField(props.session.sessionId, 'respondedToBot', true)
                setCacheByField(props.session.sessionId, 'isStartAgent', false)
                setCacheByField(props.session.sessionId, 'isBotToAgent', true)
            }
        })
    }

    const handleReplyEvent = async (event: ReceiveEventInterface) => {
        info('handleReplyEvent', 'Reply:', event);
        event.data.map((item: any, index: number, arr: []) => {
            if (item.type === 'text') {
                const content = {
                    owner: 'bot',
                    type: 'text',
                    name: props.settings.botName,
                    message: {
                        links: item.content.links ? item.content.links : null,
                        content: item.content.message,
                    },
                    displayTime: format(new Date(), 'hh:mmaa'),
                    createdBy: new Date().getTime(),
                } as ChatInterface;
                if (item.content.links) {
                    let intents = item.content.links.filter(
                        (link: any) => link.type == 'goToIntent'
                    );
                    if (intents.length > 0) {
                        dispatch(
                            editStatus({
                                name: 'intentId',
                                value: intents[intents.length - 1].intentId,
                            })
                        );
                    }
                }
                dispatch(addChat(content));
                pushCache(
                    props.session.sessionId + '_chats',
                    JSON.stringify(content)
                );
            } else if (item.type === 'quickreply') {
                dispatch(
                    addDialog({
                        task: 'connectAgent',
                        type: 'action',
                    })
                );
            } else if (item.type === 'image') {
                const content = {
                    owner: 'bot',
                    type: 'image',
                    name: props.settings.botName,
                    message: {
                        links: item.content.links ? item.content.links : null,
                        url: item.content.url,
                        description: item.content.description,
                        content: item.content.message
                            ? item.content.message
                            : null,
                    },
                    displayTime: format(new Date(), 'hh:mmaa'),
                    createdBy: new Date().getTime(),
                } as ChatInterface;
                dispatch(addChat(content));
                pushCache(
                    props.session.sessionId + '_chats',
                    JSON.stringify(content)
                );
            } else if (item.type == 'video') {
                dispatch(
                    addChat({
                        owner: 'bot',
                        type: 'video',
                        name: props.settings.botName,
                        message: {
                            links: item.content.links
                                ? item.content.links
                                : null,
                            url: item.content.url,
                            description: item.content.description,
                            content_type: 'video',
                        },
                        displayTime: format(new Date(), 'hh:mmaa'),
                        createdBy: new Date().getTime(),
                    } as ChatInterface)
                );
            } else if (item.type === 'form') {
                dispatch(
                    editSession({
                        name: 'formContent',
                        value: item.content,
                    })
                );
                dispatch(
                    editStatus({
                        name: 'isFormModal',
                        value: true,
                    })
                );
            } else if (item.type === 'imageLink'){

                let imageMessage = "";

                if (item.content.message === "Error uploading image"){

                    sendChasitorMessageEvent({
                        chatId: props.session.chatId,
                        affinityToken: props.session.laAffinityToken,
                        key: props.session.laSessionKey,
                        message: t("fileUploadFailure"),
                        type: 'message',
                        username: props.session.username,
                    });

                    imageMessage = "fileUploadFailure"

                } else {

                    sendChasitorMessageEvent({
                        chatId: props.session.chatId,
                        affinityToken: props.session.laAffinityToken,
                        key: props.session.laSessionKey,
                        message: t("fileUploadAgentSuccess")+item.content.message,
                        type: 'message',
                        username: props.session.username,
                    });

                    imageMessage = "fileUploadSuccess"

                }

                const content = {
                    owner: 'user',
                    name: username,
                    type: 'text',
                    message: {
                        content: t(imageMessage),
                    },
                    displayTime: format(new Date(), 'hh:mmaa'),
                    createdBy: new Date().getTime(),
                } as ChatInterface;
                pushCache(props.session.sessionId + '_chats', JSON.stringify(content));
                dispatch(addChat(content));
            }
            dispatch(incStatus({ name: 'notificationSounds' }));
        });
    };

    const handleMessageInsertedEvent = async (event: ReceiveEventInterface) => {
        console.log('handleMessageInsertedEvent', chatId, props.session.chatId);
        if (chatId) {
            sendConsumeMessageEvent({
                chatId,
            });
        }
    };

    const handleLiveAgentRequestSuccessEvent = (
        event: ReceiveEventInterface
    ) => {

        console.log("new queue check, offline count", offlineCount, "online count", onlineCount);


        if (event.data.newQueue && offlineCount < 1 && onlineCount < 2){

            console.log("new queue value", event.data.newQueue);

            onlineCount = onlineCount + 1;

            verifyVipChecked();

            sendConnectAgentEvent({
                chatId: chatId,
                isStartAgent: false,
                isStartBot: true,
                isVip: props.session.isVip[0],
                triggerTime: new Date().getTime().toString(),
                language: 'chinese',
                screen_resolution:
                    window.screen.width + 'x' + window.screen.height,
                userAgent: navigator.userAgent,
                nps: props.session.nps,
                matchStartDateTime: matchStartDate,
                betslipId: betslipId,
                type: 'connect',
                username: username,
                queue: props.session.queue,
                sfQueueId: event.data.newQueue,
                sfReferrer: referrerSite? referrerSite : document.referrer,
                connect_flag: "F1M5 Queue",
                browser: getBrowser(),
                sfPlatform: getPlatform(),
                acqQueueFlag: JSON.parse(customValues).hasOwnProperty('acq_queue_flag') ? JSON.parse(customValues).acq_queue_flag : "",
                enQueue: enQueue,
                acqQueueDays: JSON.parse(customValues).hasOwnProperty('acq_queue_days') ? JSON.parse(customValues).acq_queue_days : "",
            });

            sendChatEndEvent({
                chatId: chatId,
                affinityToken: event.data.live_agent_session.affinityToken,
                key: event.data.live_agent_session.key,
            });

        } else {

            let sfPostChatUrl = event.data.message.postChatUrl + "?id=" + event.data.message.visitorId;

            console.log("SF POST CHAT URL", sfPostChatUrl);

            dispatch(
                editStatus({
                    name: 'queueNumber',
                    value: event.data.message.queuePosition - 1,
                })
            );

            dispatch(
                editStatus({
                    name: 'queueStatus',
                    value: 'queueing',
                })
            );
            
            dispatch(
                editSessions({
                    laAffinityToken: event.data.live_agent_session.affinityToken,
                    laSessionKey: event.data.live_agent_session.key,
                    laSessionId: event.data.live_agent_session.id,
                    postChatUrl: sfPostChatUrl
                })
            );
            dispatch(
                editSession({
                    name: 'refreshWhenQueueing',
                    value: true,
                })
            );

            setCacheByField(props.session.sessionId, 'refreshWhenQueueing', true);
            dispatch(
                editSession({
                    name: 'currentStatusClient',
                    value: "finishRequestSuccess",
                })
            );

    
            setCacheByField(props.session.sessionId, 'refreshWhenQueueing', true);
            setCacheByField(props.session.sessionId, 'postChatUrl', sfPostChatUrl);
            setCacheByField(props.session.sessionId, 'laAffinityToken', event.data.live_agent_session.affinityToken);
            setCacheByField(props.session.sessionId, 'laSessionKey', event.data.live_agent_session.key);
            setCacheByField(props.session.sessionId, 'queueStatus', 'queueing');
            setCacheByField(props.session.sessionId, 'currentStatusClient', "finishRequestSuccess");

            
        }

        
    };

    const handleLiveAgentRequestFailEvent = (event: ReceiveEventInterface) => {

        if (offlineCount < 1 && event.data.newQueue){

            console.log("offline new queue value", event.data.newQueue, offlineCount);

            verifyVipChecked();
           
            sendConnectAgentEvent({
                chatId: chatId,
                isStartAgent: false,
                isStartBot: true,
                isVip: props.session.isVip[0],
                triggerTime: new Date().getTime().toString(),
                language: 'chinese',
                screen_resolution:
                    window.screen.width + 'x' + window.screen.height,
                userAgent: navigator.userAgent,
                nps: props.session.nps,
                matchStartDateTime: matchStartDate,
                betslipId: betslipId,
                type: 'connect',
                username: username,
                queue: props.session.queue,
                sfQueueId: event.data.newQueue,
                sfReferrer: referrerSite? referrerSite : document.referrer,
                connect_flag: "F1M5 QUEUE",
                browser: getBrowser(),
                sfPlatform: getPlatform(),
                acqQueueFlag: JSON.parse(customValues).hasOwnProperty('acq_queue_flag') ? JSON.parse(customValues).acq_queue_flag : "",
                enQueue: enQueue,
                acqQueueDays: JSON.parse(customValues).hasOwnProperty('acq_queue_days') ? JSON.parse(customValues).acq_queue_days : "",
            });

            offlineCount +=1


        } else {

            dispatch(editStatus({ name: 'queueStatus', value: 'failed' }));
            dispatch(
                addDialog({
                    task: 'ended',
                    type: 'action',
                })
            );
            dispatch(
                addDialog({
                    task: 'offlineForm',
                    type: 'action',
                })
            );
            endSession(props.session.sessionId);
            dispatch(
                editStatus({
                    name: 'isIframeModal',
                    value:
                        true &&
                        props.settings.enableOfflineForm &&
                        props.settings.useExternalLinkForOfflineForm,
                })
            );
            dispatch(
                editStatus({
                    name: 'iframeUrl',
                    value: props.settings.externalLinkForOfflineForm,
                })
            );
    
            console.log("Live Agent request failed, webtocase triggered");
    
            sendUploadChatEvent({
                chatId: chatId,
                speaker: 'Client',
                speakerName: props.settings.botName,
                datetime: new Date().toJSON(),
                message: "Web-to-Case triggered due to Agent Request Failed",
                is_system: true,
            });

        }
       

    };

    const handleLiveAgentQueueUpdateEvent = (event: ReceiveEventInterface) => {
        dispatch(
            editStatus({
                name: 'queueNumber',
                value: event.data.message.position,
            })
        );

        dispatch(
            editSession({
                name: 'currentStatusClient',
                value: "Done",
            })
        );

        setCacheByField(props.session.sessionId, 'currentStatusClient', "Done");
    };

    const handleLiveAgentChatEstablishedEvent = async (
        event: ReceiveEventInterface
    ) => {
        console.log('isChatAlive', isChatAlive);
        if (!isChatAlive) {
            return;
        }
        console.log('sendChatCountsEvent ', chatModeLocal);
        if (chatModeLocal == 'bot') {
            if (!isStartBot) {
                dispatch(
                    editSession({
                        name: 'isStartBot',
                        value: true,
                    })
                );
            };
            dispatch(
                editSessions({
                    isBotToAgent: true,
                    chatCountReason: 'isBotToAgent',
                })
            );
            setCacheByField(props.session.sessionId, 'isBotToAgent', true);
            setCacheByField(props.session.sessionId, 'isStartBot', true);
            const cache_chats = await popCache(
                props.session.sessionId + '_chats'
            );

            console.log('Pop cache', cache_chats);

            let transcript = `${t('history')}:\n`;
            cache_chats.map((chat: string, i: number) => {
                let chatObj = JSON.parse(chat);

                if (chatObj.type == 'text') {
                    let content = chatObj.message.content.replace(
                        /(<([^>]+)>)/gi,
                        ''
                    );
                    transcript +=
                        `${chatObj.displayTime}: ${chatObj.name} : ${content}` +
                        '\n';
                } else if (chatObj.type == 'image' || chatObj.type == 'video') {
                    transcript +=
                        `${chatObj.displayTime}: ${chatObj.name} : ${chatObj.message.url}` +
                        '\n';
                }
            });
            setTimeout(() => {
                sendChasitorMessageEvent({
                    chatId: props.session.chatId,
                    affinityToken: event.data.live_agent_session.affinityToken,
                    key: event.data.live_agent_session.key,
                    message: transcript,
                    type: 'message',
                    username: props.session.username,
                });
            }, 5000);
            editSession({ name: 'chatMode', value: 'agent' });
        }
        setAgentName(String(event.data.live_agent_info.name));
        dispatch(editStatus({ name: 'queueStatus', value: 'established' }));
        dispatch(
            editSessions({
                chatMode: 'agent',
                laAffinityToken: event.data.live_agent_session.affinityToken,
                laSessionKey: event.data.live_agent_session.key,
                laSessionId: event.data.live_agent_session.id,
                laName: event.data.live_agent_info.name,
            })
        );

        dispatch(
            editSession({
                name: 'refreshWhenQueueing',
                value: false,
            })
        );
        
        setCacheByField(props.session.sessionId, 'refreshWhenQueueing', false);

        dispatch(
            editSession({
                name: 'currentStatusClient',
                value: "Done",
            })
        );
        
        setCacheByField(props.session.sessionId, 'refreshWhenQueueing', false);
        setCacheByField(props.session.sessionId, 'queueStatus', 'established');
        setCacheByField(props.session.sessionId, 'currentStatusClient', "Done");

        if (checkBetSlipValue()){
            dispatch(
                addDialog({
                    task: 'betSlip',
                    type: 'action',
                    data: '',
                })
            );

            let slipVals = [vendor, status, betslipId, matchStartDate];
            dispatch(
                editSession({
                    name: 'betSlipVals',
                    value: slipVals,
                })
            );

            setCacheByField(props.session.sessionId, 'betSlipVals', slipVals);

        }

        if (caseNumber && caseCategory){
            dispatch(
                addDialog({
                    task: 'caseDetails',
                    type: 'action',
                    data: '',
                })
            );
        }
        // for (let step = 0; step < 1000; step++) {
        //     // Runs 1000 times, with values of step 0 through 4.
        //     console.log('Duplicating cache', step);
        //     setCachesByFields([
        //         {
        //             session: props.session.sessionId,
        //             field: 'chatMode' + step.toString(),
        //             value: 'agent',
        //         },
        //         {
        //             session: props.session.sessionId,
        //             field: 'laAffinityToken' + step.toString(),
        //             value: event.data.live_agent_session.affinityToken,
        //         },
        //         {
        //             session: props.session.sessionId,
        //             field: 'laSessionKey' + step.toString(),
        //             value: event.data.live_agent_session.key,
        //         },
        //         {
        //             session: props.session.sessionId,
        //             field: 'laSessionId' + step.toString(),
        //             value: event.data.live_agent_session.id,
        //         },
        //         {
        //             session: props.session.sessionId,
        //             field: 'laName' + step.toString(),
        //             value: event.data.live_agent_info.name,
        //         },
        //     ]);
        // }

        // setCacheByField(props.session.sessionId, 'chatMode', 'agent');

        setCachesByFields([
            {
                sessionId:
                    props.session.botId.toString() + props.session.sessionId,
                field: 'chatMode',
                value: 'agent',
            },
            {
                sessionId:
                    props.session.botId.toString() + props.session.sessionId,
                field: 'laAffinityToken',
                value: event.data.live_agent_session.affinityToken,
            },
            {
                sessionId:
                    props.session.botId.toString() + props.session.sessionId,
                field: 'laSessionKey',
                value: event.data.live_agent_session.key,
            },
            {
                sessionId:
                    props.session.botId.toString() + props.session.sessionId,
                field: 'laSessionId',
                value: event.data.live_agent_session.id,
            },
            {
                sessionId:
                    props.session.botId.toString() + props.session.sessionId,
                field: 'laName',
                value: event.data.live_agent_info.name,
            },
        ]);

        const liveAgentConnectedMessage = {
            owner: 'system',
            name: 'Mario',
            type: 'text',
            message: {
                content: props.systemMessages.live_agent_connected.message,
            },
            displayTime: format(new Date(), 'hh:mmaa'),
            createdBy: new Date().getTime(),
        } as ChatInterface;
        dispatch(addChat(liveAgentConnectedMessage));
        pushCache(
            props.session.sessionId + '_chats',
            JSON.stringify(liveAgentConnectedMessage)
        );
        dispatch(
            editSession({
                name: 'laId',
                value: event.data.live_agent_info.userId,
            })
        );
        const liveAgentJoinedMessage = {
            owner: 'system',
            name: 'Mario',
            type: 'text',
            message: {
                content: props.systemMessages.live_agent_joined.message.replace(
                    /(\{).+?(\})/g,
                    event.data.live_agent_info.name
                ),
            },
            displayTime: format(new Date(), 'hh:mmaa'),
            createdBy: new Date().getTime(),
        } as ChatInterface;
        dispatch(addChat(liveAgentJoinedMessage));
        pushCache(
            props.session.sessionId + '_chats',
            JSON.stringify(liveAgentJoinedMessage)
        );
    };

    const handleLiveAgentChatTransferredEvent = async (
        event: ReceiveEventInterface
    ) => {
        dispatch(
            editSessions({
                laName: event.data.live_agent_info.name,
                laId: event.data.live_agent_info.userId,
            })
        );
        const liveAgentTransferredMessage = {
            owner: 'system',
            name: 'Mario',
            type: 'text',
            message: {
                content: props.systemMessages.live_agent_transferred.message,
            },
            displayTime: format(new Date(), 'hh:mmaa'),
            createdBy: new Date().getTime(),
        } as ChatInterface;
        dispatch(addChat(liveAgentTransferredMessage));
        pushCache(
            props.session.sessionId + '_chats',
            JSON.stringify(liveAgentTransferredMessage)
        );
        const liveAgentJoinedMessage = {
            owner: 'system',
            name: 'Mario',
            type: 'text',
            message: {
                content: props.systemMessages.live_agent_joined.message.replace(
                    /(\{).+?(\})/g,
                    event.data.live_agent_info.name
                ),
            },
            displayTime: format(new Date(), 'hh:mmaa'),
            createdBy: new Date().getTime(),
        } as ChatInterface;
        dispatch(addChat(liveAgentJoinedMessage));
        pushCache(
            props.session.sessionId + '_chats',
            JSON.stringify(liveAgentJoinedMessage)
        );
    };

    const handleLiveAgentChatMessageEvent = (event: ReceiveEventInterface) => {
        if (isChatAlive) {
            const content = {
                owner: 'agent',
                type: 'text',
                name: event.data.message.name,
                message: {
                    content: event.data.message.text,
                },
                displayTime: format(new Date(), 'hh:mmaa'),
                createdBy: new Date().getTime(),
            } as ChatInterface;
            dispatch(addChat(content));
            dispatch(incStatus({ name: 'notificationSounds' }));
            pushCache(
                props.session.sessionId + '_chats',
                JSON.stringify(content)
            );
        }
    };

    const handleLiveAgentFileRequestEvent = (event: ReceiveEventInterface) => {
        if (event.data.message.type === 'Requested') {
            dispatch(
                addDialog({
                    task: 'uploadFile',
                    type: 'action',
                    data: event.data.message,
                })
            );
        } else {
            let uploadFileSysMessage = 'fileUpload' + event.data.message.type;

            const uploadFileMessage = {
                owner: 'system',
                name: 'Mario',
                type: 'text',
                message: {
                    content: t(uploadFileSysMessage),
                },
                displayTime: format(new Date(), 'hh:mmaa'),
                createdBy: new Date().getTime(),
            } as ChatInterface;
            dispatch(addChat(uploadFileMessage));
            pushCache(
                props.session.sessionId + '_chats',
                JSON.stringify(uploadFileMessage)
            );
            dispatch(removeDialog('uploadFile'));
        }
    };

    const handleLiveAgentChatEnded = (event: ReceiveEventInterface) => {
        const content = {
            owner: 'system',
            name: 'Mario',
            type: 'text',
            message: {
                content: props.systemMessages.live_agent_ended.message.replace(
                    /(\{).+?(\})/g,
                    agentName
                ),
            },
            displayTime: format(new Date(), 'hh:mmaa'),
            createdBy: new Date().getTime(),
        } as ChatInterface;
        dispatch(addChat(content));
        pushCache(props.session.sessionId + '_chats', JSON.stringify(content));
        dispatch(
            addDialog({
                task: 'ended',
                type: 'action',
            })
        );
        endSession(props.session.sessionId);
    };

    const checkValidSession = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const paramsToken = searchParams.get('token');
                const cookiesToken = await sessionService.getCacheSession(props.session.acctId, props.session.botId);

                const token = paramsToken ? paramsToken : cookiesToken
                if (token) {
                    // const cache = await getCache(cachedToken);
                    const chatId = await getCacheByField(token, "chatId");
                    console.log('test_ CHAT ID', chatId, 'SESSION', token);
                    if (chatId) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    } 
                }
                else {
                    resolve(false);
                }
            } catch (e) {
                reject(false)
            }
        });
    };

    const connectEvent = () => {
        if (socket) {
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'connect';
            _socketEvent.timestamp = new Date().getTime();
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    // const checkValidSession = async () => {
    //         return new Promise(async (resolve, reject) => {
    //             try {
    //                 const cachedToken = await sessionService.getCacheSession(props.session.acctId, props.session.botId);
    //                 if (cachedToken) {
    //                     const laAffinityToken = await getCacheByField(cachedToken, 'laAffinityToken');
    //                     const laSessionKey = await getCacheByField(cachedToken, 'laSessionKey');
    //                     const laSessionId = await getCacheByField(cachedToken, 'laSessionId');
    //                     const laName = await getCacheByField(cachedToken, 'laName');
    //                     console.log('CACHE HERE sf values', 'laAffinityToken',  laAffinityToken, 'laSessionKey', laSessionKey, 'laSessionId', laSessionId, 'laName', laName)
    //                     if (laAffinityToken && laSessionKey && laSessionId && laName) {
    //                         resolve(true);
    //                     }
    //                     else {
    //                         resolve(false);
    //                     } 
    //                 }
    //                 else {
    //                     resolve(false);
    //                 }
    //             } catch (e) {
    //                 reject(false)
    //             }
    //         });
    //     };

    const connectAgent = (connectVal : {isVip?: any, flag?:string}) => {
        let tempVip = connectVal.isVip.toString();
        let tempPropsVip = props.session.isVip[0].toString();
        const vipStatus = tempVip ? tempVip : tempPropsVip;
        let flagVal = connectVal.flag? connectVal.flag : "";

        console.log("VIPVALS", vipStatus === "true"? true: false, vipStatus);
        if (botIdRequireLanguageSelection.includes(props.session.botId)) {
            Swal.fire({
                text: t('Preferred Language'),
                input: 'select',
                inputOptions: {
                    en: 'English',
                    hindi: 'हिंदी',
                },
                inputPlaceholder: t('Please select your language'),
                showCancelButton: false,
                confirmButtonColor: botButtonColor,
                allowOutsideClick: false,
                confirmButtonText: t('Confirm'),
                inputValidator: (value) => {
                    return new Promise((resolve: any) => {
                        if (value) {
                            resolve();
                        } else {
                            resolve(t('Please select your preferred language'));
                        }
                    });
                },
            }).then((result) => {
                if (result.value) {
                    dispatch(
                        editStatus({ name: 'queueStatus', value: 'loading' })
                    );
                    sendConnectAgentEvent({
                        chatId: props.session.chatId,
                        isStartAgent: true, //check
                        isStartBot: false, //check
                        isVip: vipStatus === "true"? true: false,
                        triggerTime: new Date().getTime().toString(),
                        language: 'chinese',
                        screen_resolution:
                            window.screen.width + 'x' + window.screen.height,
                        userAgent: navigator.userAgent,
                        nps: props.session.nps,
                        matchStartDateTime: props.session.matchStartDate,
                        betslipId: props.session.betslipId,
                        type: 'connect',
                        username: props.session.username,
                        queue: props.session.queue,
                        sfQueueId: result.value ?? '',
                        sfReferrer: referrerSite? referrerSite : document.referrer,
                        connect_flag: flagVal,
                        browser: getBrowser(),
                        sfPlatform: getPlatform(),

                        //cacheId: props.session.botId.toString()+props.session.sessionId,
                        //cacheUrl : props.session.apiPath,
                        acqQueueFlag: JSON.parse(customValues).hasOwnProperty('acq_queue_flag') ? JSON.parse(customValues).acq_queue_flag : "",
                        enQueue: enQueue,
                        cacheId: props.session.botId.toString()+props.session.sessionId,
                        cacheUrl : props.session.apiPath,
                        acqQueueDays: JSON.parse(customValues).hasOwnProperty('acq_queue_days') ? JSON.parse(customValues).acq_queue_days : "",
                    });
                }
            });
        } else {
            dispatch(editStatus({ name: 'queueStatus', value: 'loading' }));
            sendConnectAgentEvent({
                chatId: props.session.chatId,
                isStartAgent: true, //check
                isStartBot: false, //check
                isVip: vipStatus === "true"? true: false,
                triggerTime: new Date().getTime().toString(),
                language: 'chinese',
                screen_resolution:
                    window.screen.width + 'x' + window.screen.height,
                userAgent: navigator.userAgent,
                nps: props.session.nps,
                matchStartDateTime: props.session.matchStartDate,
                betslipId: props.session.betslipId,
                type: 'connect',
                username: props.session.username,
                queue: props.session.queue,
                sfReferrer: referrerSite? referrerSite : document.referrer,
                connect_flag: flagVal,
                browser: getBrowser(),
                sfPlatform: getPlatform(),

                //cacheId: props.session.botId.toString()+props.session.sessionId,
                //cacheUrl : props.session.apiPath,

                cacheId: props.session.botId.toString()+props.session.sessionId,
                cacheUrl : props.session.apiPath,
                acqQueueFlag: JSON.parse(customValues).hasOwnProperty('acq_queue_flag') ? JSON.parse(customValues).acq_queue_flag : "",
                enQueue: enQueue,
                acqQueueDays: JSON.parse(customValues).hasOwnProperty('acq_queue_days') ? JSON.parse(customValues).acq_queue_days : "",
            });
        }
    };

    const sendCheckVipEvent = (data: SendCheckVipEventInterface) => {
        if (socket) {
            if (data["usernameFromUrl"] === false) {
                data["chatId"] = props.session.chatId
            }
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'checkVip';
            _socketEvent.data = data;
            console.log('sendCheckVipEvent', _socketEvent);
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    // const sendChatCountsEvent = (data: any) => {
    //     console.log('sendChatCounts function', data);
    //     if (socket) {
    //         let _socketEvent = Object.assign({}, socketEvent);
    //         _socketEvent.eventType = 'chatCountsSubmit';
    //         _socketEvent.data = data;
    //         console.log('sendChatCountsEvent', _socketEvent);
    //         socket.send(JSON.stringify(_socketEvent));
    //     }
    // };

    const sendMessageEvent = async (data: SendMessageEventInterface) => {
        if (socket) {
            checkCache(props.session.sessionId, props.session.tabId);
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'message';
            _socketEvent.data = data;
            console.log('sendMessageEvent', _socketEvent);
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    const sendUploadChatEvent = async (data: SendUploadChatEventInterface) => {
        if (socket) {
            checkCache(props.session.sessionId, props.session.tabId);
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'uploadChat';
            _socketEvent.data = data;
            console.log('sendUploadChatEvent', _socketEvent);
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    const sendPingEvent = (chatId: string) => {
        if (socket) {
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'ping';
            _socketEvent.data = { chatId: chatId };
            // dispatch(appendSession({ name: 'debugMessage', value: 'pi-' }));
            console.log('ping', _socketEvent, socket);
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    const sendIntentEvent = async (data: SendIntentEventInterface) => {
        if (chatMode != 'agent') {
            if (socket) {
                let _socketEvent = Object.assign({}, socketEvent);
                _socketEvent.eventType = 'goToIntent';
                _socketEvent.data = data;
                console.log('sendIntentEvent', _socketEvent);
                socket.send(JSON.stringify(_socketEvent));
            }
        }

        // if (socket) {
        //     let _socketEvent = Object.assign({}, socketEvent);
        //     _socketEvent.eventType = 'goToIntent';
        //     _socketEvent.data = data;
        //     console.log('sendIntentEvent', _socketEvent);
        //     socket.send(JSON.stringify(_socketEvent));
        // }
    };

    const sendFormSubmitEvent = (data: SendFormSubmitEventInterface) => {
        if (socket) {
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'formSubmit';
            _socketEvent.data = data;
            console.log(_socketEvent);
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    const sendConnectAgentEvent = (data: SendConnectAgentEventInterface) => {
        if (socket) {
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'connectAgent';
            _socketEvent.data = data;
            console.log(_socketEvent);
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    const sendReconnectAgentEvent = (
        data: SendReconnectAgentEventInterface
    ) => {
        if (socket) {
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'reconnectAgent';
            _socketEvent.data = data;
            console.log(_socketEvent);
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    const sendVerifyReconnectionEvent = (
        data: SendVerifyReconnectionEventInterface
    ) => {
        if (socket) {
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'verifyReconnection';
            _socketEvent.data = data;
            console.log(_socketEvent);
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    const sendConsumeMessageEvent = (
        data: SendConsumeMessageEventInterface
    ) => {
        if (socket) {
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'consumeMessage';
            _socketEvent.data = data;
            console.log('SendConsumeMessageEventInterface', _socketEvent);
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    const sendChasitorMessageEvent = (
        data: SendChasitorMessageEventInterface
    ) => {
        if (socket) {
            checkCache(props.session.sessionId, props.session.tabId);
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'chasitorChatMessage';
            _socketEvent.data = data;
            console.log('sendChasitorMessageEvent', _socketEvent);
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    const sendChasitorTypingEvent = (
        data: SendVerifyReconnectionEventInterface
    ) => {
        if (socket) {
            checkCache(props.session.sessionId, props.session.tabId);
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'chasitorTyping';
            _socketEvent.data = data;
            console.log('sendchasitorTypingEvent', _socketEvent);
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    const sendChasitorNotTypingEvent = (
        data: SendVerifyReconnectionEventInterface
    ) => {
        if (socket) {
            checkCache(props.session.sessionId, props.session.tabId);
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'chasitorNotTyping';
            _socketEvent.data = data;
            console.log('sendchasitorNotTypingEvent', _socketEvent);
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    const sendUploadFileEvent = (data: SendUploadFileEventInterface) => {
        if (socket) {
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'uploadFile';
            _socketEvent.data = data;
            console.log(_socketEvent);
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    const sendChatRatingEvent = async (data: SendChatRatingInterface) => {
        if (socket) {
            if (chatMode == 'bot') {
                data['agentName'] = props.settings.botName;
            }
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'ratingSubmit';
            _socketEvent.data = data;
            console.log('CHAT RATING', _socketEvent);
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    const sendChatEndEvent = async (data: SendChatEndInterface) => {
        if (socket) {
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'chasitorChatEnd';
            _socketEvent.data = data;
            console.log('chasitorChatEnd', _socketEvent);
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    const sendAuthEvent = async (data: SendHandshakeTokenEvent) => {
        if (socket) {
            let _socketEvent = Object.assign({}, socketEvent);
            _socketEvent.eventType = 'authenticate';
            _socketEvent.data = data;
            console.log('authenticationData', _socketEvent);
            socket.send(JSON.stringify(_socketEvent));
        }
    };

    const onError = (err: any) => {
        error(SocketContextProvider.name, `Socket on error: ${err}`);
        dispatch(appendSession({ name: 'debugMessage', value: err }));
        dispatch(addDialog({ task: 'disconnected', type: 'action' }));
        dispatch(editStatus({ name: 'isChatAlive', value: false }));
    };

    const onClose = (e: any) => {
        info(SocketContextProvider.name, `Socket on close: ${e}`);
        dispatch(appendSession({ name: 'debugMessage', value: 'close-' }));
        dispatch(addDialog({ task: 'disconnected', type: 'action' }));
        dispatch(editStatus({ name: 'isChatAlive', value: false }));
    };

    const generator = function* (array: string[]) {
        for (let i = 0; i < array.length; i++) {
            yield array[i];
        }
    };
    const socketPathsGenerator = generator(props.socketPaths);

    useEffect(() => {
        let socketPath = socketPathsGenerator.next();
        if (!socketPath.done) {
            setPath(socketPath.value);
        }
    }, []);

    useEffect(() => {
        info(SocketContextProvider.name, 'connecting socket', path);
        try {
            let wSocket: WebSocket = new WebSocket(path);
            wSocket.onopen = function (e) {
                setSocket(wSocket);
                info(
                    SocketContextProvider.name,
                    'socket opened:',
                    socket,
                    path
                );
                dispatch(
                    editSession({
                        name: 'socketPaths',
                        value: JSON.stringify([path]),
                    })
                );

                setCacheByField(
                    props.session.sessionId,
                    'socketPaths',
                    JSON.stringify(path)
                );
            };
            wSocket.onerror = function (e) {
                error(SocketContextProvider.name, 'socket error:', e);
                dispatch(
                    appendSession({ name: 'debugMessage', value: `error- ${e}` })
                );
                let socketPath = socketPathsGenerator.next();
                if (!socketPath.done) {
                    setPath(socketPath.value);
                } else {
                    error(SocketContextProvider.name, 'No socket path to try.');
                }
            };
            wSocket.onclose = function (e) {
                dispatch(
                    appendSession({ name: 'debugMessage', value: 'closed-' })
                );
            };
        } catch (e) {
            error(SocketContextProvider.name, `Socket connecting error:`, e);
        }
    }, [path]);

    // const initSocketConnection = async () => {
    //     if (socket != null) {
    //         connectEvent();
    //     }
    // };

    const initSocketConnection = async () => {
        if (socket != null) {
            const activeChatId = await checkValidSession();
            if (activeChatId) {
                const reloadedWindow = localStorage.getItem('reloadedWindow')
                console.log('test_ remain existing chat!')
            //     if (!reloadedWindow) {
            //         window.location.reload();
            //     } else {
            //     localStorage.removeItem('reloadedWindow');
            // }
            console.log("initSocketConnection", props.session.chatId);
            connectEvent();
            
            //handleConnectedEvent({"eventType": "connected", "data": {"chatId": props.session.chatId}});
            // if (chatMode === 'agent') {
            //     sendReconnectAgentEvent({
            //         chatId: props.session.chatId,
            //         username: props.session.username,
            //     });
            // }
        } else {
            console.log('test_ connectevent here');
            connectEvent(); // creates new chat id in plugin
        }
    };
};

  useEffect(() => {
    const handleBeforeUnload = () => {
        localStorage.setItem('reloadedWindow', 'true');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    // clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


    useEffect(() => {
        if (socket != null) {
            info(SocketContextProvider.name, 'add listener', socket);
            socket.removeEventListener('message', onMessage);
            socket.addEventListener('open', onOpen);
            socket.addEventListener('message', onMessage);
            socket.addEventListener('error', onError);
            socket.addEventListener('close', onClose);

            return () => {
                socket.removeEventListener('message', onMessage);
            };
        } else {
            warning(SocketContextProvider.name, 'socket is not null', socket);
        }
    }, [socket, chatId, chatModeLocal, agentName, username, isChatAlive]);

    useEffect(() => {
        if (socket != null) {
            initSocketConnection();
            dispatch(
                editStatus({
                    name: 'isLoading',
                    value: false,
                })
            );
        } else {
            warning(SocketContextProvider.name, 'socket is not null', socket);
        }
    }, [socket]);

    // useEffect(() => {
    //     if (socket) {
    //         if (
    //             isStartBot ||
    //             isStartAgent ||
    //             isBotToAgent ||
    //             respondedToBot ||
    //             respondedToAgent
    //         )
    //             sendChatCountsEvent({
    //                 chatId,
    //                 username: session.username,
    //                 isStartBot,
    //                 isStartAgent,
    //                 isBotToAgent,
    //                 respondedToBot,
    //                 respondedToAgent,
    //                 reason: chatCountReason,
    //                 datetime: new Date().toJSON(),
    //             });
    //             startSessionChecker(chatId);
    //     }
    // }, [chatCountReason]);

    return (
        <SocketContext.Provider
            value={{
                socket,
                sendChasitorMessageEvent,
                sendChasitorTypingEvent,
                sendChasitorNotTypingEvent,
                sendChatEndEvent,
                sendChatRatingEvent,
                sendCheckVipEvent,
                sendConnectAgentEvent,
                sendConsumeMessageEvent,
                sendFormSubmitEvent,
                sendIntentEvent,
                sendMessageEvent,
                sendPingEvent,
                sendReconnectAgentEvent,
                sendUploadFileEvent,
                sendVerifyReconnectionEvent,
                sendUploadChatEvent,
                sendAuthEvent,
            }}
        >
            {props.children}
        </SocketContext.Provider>
    );
};

export default SocketContextProvider;
