import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import { useSocket } from '../../hook/useSocket';
import {
    JsFuntionInterface,
    ReduxFunctionInterface,
    SendIntentEventInterface,
} from '../../utils/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { editSession, editSessions } from '../../redux/state/session';
import classNames from 'classnames';
import { getOS } from '../../utils/navigator';
import { useCacheService } from '../../hook/useCacheService';

interface IntentProps {
    text: string;
    intentId?: string | number;
}

const IntentText = (props: IntentProps) => {
    const socket = useSocket();

    const dispatch = useDispatch();

    const cacheService = useCacheService();

    const chatId = useSelector(
        (state: { session: { chatId: string } }) => state.session.chatId
    );

    const sessionId = useSelector(
        (state: { session: { sessionId: string } }) => state.session.sessionId
    );

    const botButtonColor = useSelector(
        (state: { settings: { botButtonColor: string } }) =>
            state.settings.botButtonColor
    );

    const botFontSize = useSelector(
        (state: { settings: { botFontSize: number } }) =>
            state.settings.botFontSize
    );

    const intentFontSize = useSelector(
        (state: { settings: { intentFontSize: number } }) =>
            state.settings.intentFontSize
    );

    const botLanguage = useSelector(
        (state: { settings: { botLanguage: string } }) =>
            state.settings.botLanguage
    );

    const processor = useSelector(
        (state: { settings: { processor: number } }) =>
            state.settings.processor
    );

    const username = useSelector(
        (state: { session: { username: string } }) => state.session.username
    );

    const isStartBot = useSelector(
        (state: { session: { isStartBot: boolean } }) =>
            state.session.isStartBot
    );

    const respondedToBot = useSelector(
        (state: { session: { respondedToBot: boolean } }) =>
            state.session.respondedToBot
    );

    const respondedToAgent = useSelector(
        (state: { session: { respondedToAgent: boolean } }) =>
            state.session.respondedToAgent
    );

    const chatCountReason = useSelector(
        (state: { session: { chatCountReason: string } }) =>
            state.session.chatCountReason
    );

    useEffect(() => {
        console.log('chatcount respond', respondedToBot, respondedToAgent);
        cacheService.setCacheByField(
            sessionId,
            'respondedToBot',
            respondedToBot
        );
        cacheService.setCacheByField(sessionId, 'isStartBot', isStartBot);
    }, [respondedToBot]);

    const fontSizesRef = ['xs', 'sm', 'base', 'lg', 'xl'];
    const smallFontSizeRef = ['2xs', 'xs', 'sm', 'base', 'lg'];
    const fontSizes = [
        fontSizesRef[0 + intentFontSize],
        fontSizesRef[1 + intentFontSize],
        fontSizesRef[2 + intentFontSize],
    ];
    const smallFontSizes = [
        smallFontSizeRef[0 + intentFontSize],
        smallFontSizeRef[1 + intentFontSize],
        smallFontSizeRef[2 + intentFontSize],
    ];

    // dispatch(editSession({ name: 'intentId', value: props.intentId }));

    let data = {
        username: username,
        chatId: chatId,
        type: 'goToIntent',
        intentId: props.intentId,
        triggerTime: new Date().toJSON(),
        customer_lang_culture:
            botLanguage.toLowerCase() === 'chinese'
                ? 'zh-cn'
                : botLanguage.toLowerCase() === 'thai'
                ? 'th-th'
                : botLanguage.toLowerCase() === 'vietnamese'
                ? 'vi-vn'
                : botLanguage.toLowerCase() === 'vietnam'
                ? 'vi-vn'
                : botLanguage.toLowerCase() === 'english'
                ? 'en'
                : botLanguage.toLowerCase(),
        operating_system: getOS(navigator),
        screen_resolution: window.screen.width + '*' + window.screen.height,
        platform : 'mario',
        group_name : '',
        processor : processor
    } as SendIntentEventInterface;

    // console.log(data)

    const fns = [
        {
            type: 'js',
            func: function () {
                socket.sendIntentEvent(data);
                if (!respondedToBot && chatCountReason != 'respondedToBot') {
                    if (!isStartBot) {
                        dispatch(
                            editSession({
                                name: 'isStartBot',
                                value: true,
                            })
                        );
                    };
                    dispatch(
                        editSessions({
                            respondedToBot: true,
                            chatCountReason: 'respondedToBot',
                        })
                    );
                }
            },
        } as JsFuntionInterface,
    ];

    return (
        // <button
        //     type="button"
        //     className="border-2 border-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-3 py-1.5 text-center inline-flex items-center"
        // >
        //     {props.text}
        //     <svg
        //         className="ml-2 -mr-1 w-5 h-5 fill-blue-700"
        //         viewBox="0 0 20 20"
        //         xmlns="http://www.w3.org/2000/svg"
        //     >
        //         <path
        //             fill-rule="evenodd"
        //             d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
        //             clip-rule="evenodd"
        //         ></path>
        //     </svg>
        // </button>
        <Button
            fns={fns}
            className={classNames(
                `text-white font-medium rounded-lg text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]} px-2 py-1.5 m-1 text-left inline-flex break-all`,
                {
                    'bg-sky-500 hover:bg-sky-700': !botButtonColor,
                }
            )}
            style={{ backgroundColor: botButtonColor?.slice(0, 7) }}
        >
            {props.text}
        </Button>
        // <button onClick={() => {}} className="text-white bg-sky-500 hover:bg-sky-700 font-medium rounded-lg text-sm px-5 py-2.5 m-1 text-center">
        //     {props.text}
        // </button>
    );
};

IntentText.propTypes = {};

export default IntentText;
